<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Consulte os pedidos de compra realizados na tela de criação, visualizando detalhes como status, data e produtos.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-sm-6 col-lg-3 mb-3">
							<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
							<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
								<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.loja">
								<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
							</select>
                  </div>
                  <div class="col-sm-6 col-lg-3 mb-3">
							<label><i class="far fa-exchange-alt color-theme font-11 me-1"></i> Status</label>
							<v-select name="Desktop" :options="['Pendente', 'Finalizado']" v-model="pesquisa.status" :searchable="false" placeholder="Todos os status" />
							<select name="Mobile" class="form-control" v-model="pesquisa.status">
								<option v-for="(option, index) in ['Pendente', 'Finalizado']" :key="index" :value="option">{{ option }}</option>
							</select>
                  </div>
                  <div class="col-9 col-lg-5 mb-3">
							<label><i class="far fa-clipboard color-theme me-1"></i> Nome</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarPedidos" placeholder="Busque por CPF/CNPJ do fornecedor ou nome do pedido" />
                  </div>
						<div class="col-3 col-lg-1 mb-3 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="buscarPedidos"><i class="fas fa-search font-13"></i></button>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<pedido v-for="(pedido, index) in pesquisa.retorno.resultado" :key="index" :pedido="pedido" :index="index" :showEdit="true" @visualizar="buscarPedido($event)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalPedido -->
      <div class="modal fade" id="modalPedido" tabindex="-1" aria-labelledby="modalPedidoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalPedidoLabel">Pedido {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="row">
							<div class="col-6 px-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-clipboard color-theme font-10 me-1"></i> Nome</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.nome }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-calendar color-theme font-10 me-1"></i> Data</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.data == null ? 'Sem data' : modal.data }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 px-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-truck color-theme font-10 me-1"></i> CPF/CNPJ Fornecedor</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.cpfCnpjFornecedor }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Valor total</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1"><small>R$ </small>{{ modal.valorTotal == null ? '0,00' : parseFloat(modal.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 px-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-store color-theme font-10 me-1"></i> Loja</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.nomeLoja == null ? 'Sem loja' : modal.nomeLoja }}<small class="mx-2">|</small>{{ modal.numeroLoja == null ? '?' : modal.numeroLoja }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-apple-alt color-theme font-11 me-1"></i> Quant. itens</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.produtos.length }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-12 px-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-barcode color-theme font-11 me-1"></i> Chave nota fiscal</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.chaveNota == null ? 'Não definido' : modal.chaveNota }}</span>
										</p>
									</div>
								</div>
							</div>
						</div>

						<div class="card mt-1">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<h6 class="mb-0 mt-1 font-15"><i class="far fa-carrot color-theme font-13 me-2"></i>Produtos</h6>
									</div>

                           <produto v-for="(produto, index) in modal.produtos" :key="index" :produto="produto" :index="index" :campos="{}" />
									
									<div class="col-12 my-5 text-center" v-if="modal.produtos.length == 0">
										<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
										<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="imprimir">Imprimir</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>

		<impressao id="impressao" class="d-none" titulo="Pedido" :data="modal.data" :cabecalho="{
			'Nome': modal.nome, 
			'Loja': modal.nomeLoja +' / Nº '+ modal.numeroLoja, 
			'CPF/CNPJ Fornecedor': modal.cpfCnpjFornecedor, 
			}" :itensKey="{'nome': 'Descrição', 'quantidade': 'Qtd.', 'custo': 'Custo'}" :itens="modal.produtos" :total="modal.valorTotal" />
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import produto from '@/components/modelos/Produto2.vue'
import impressao from '@/components/impressao/ComprovanteA4.vue'
import pedido from '@/components/compras/pedidosConsulta/Pedido.vue'

export default {
   name: 'Consulta_pedidos',
   data: function () {
		return {
			pesquisa: {'loja': null, 'status': null, 'nome': '', 'retorno': {'loja': null, 'status': null, 'nome': null, 'resultado': []}},
			modal: {'nome': '', 'cpfCnpjFornecedor': '', 'idLoja': null, 'nomeLoja': null, 'numeroLoja': null, 'produtos': [], 'valorTotal': '0.00', 'chaveNota': null}
		}
	},
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
   watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		pedido, impressao, produto
	},
   methods: {
      imprimir : function () {
         setTimeout(() => this.$htmlToPaper('impressao', {styles: ['./comprovanteA4.css']}), 250);
      },
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Pedidos',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'nome', 'data', 'nomeLoja', 'numeroLoja', 'cpfCnpjFornecedor', 'valorTotal'], 
				'lista': null
			})
      },
      buscarPedidos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'compras/searchPedidos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
      buscarPedido : function (pedido) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'compras/getPedido',
				params: {
					id: pedido.id
				}
			}).then(response => {
				$('#modalPedido').modal('show')
				this.modal = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
   },
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>